<template>
  <div class="card card-custom gutter-b">
    <div class="card-body">
      <div class="d-flex">
        <div v-if="!isInitialLoading && kunde" class="flex-grow-1">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center mr-3">
              <a
                class="d-flex align-items-center flex-wrap text-dark text-nowrap font-size-h5 font-weight-bold mr-3"
              >
                {{ kunde.anrede }} {{ kunde.titel }} {{ kunde.vorname }} {{ kunde.nachname }}</a
              >
            </div>
          </div>
          <div class="d-flex">
            <div class="col-md-12 row mt-3">
              <div class="col-md-4 d-flex flex-wrap flex-column" style="row-gap: 0.5rem">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{
                    $t('DUBLETTEN.FILTER.KUNDENNUMMER')
                  }}</span>
                  <span class="text-muted">{{ kunde.id || '-' }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.RECENTREGION') }}</span>
                  <span class="text-muted">
                    {{ kunde.aktuellsteRegion ? $t('REISEN.REGIONS.' + kunde.aktuellsteRegion) : '-' }}</span
                  >
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.RECENTCOUNTRY') }}</span>
                  <span class="text-muted">
                    {{ kunde.aktuellsteLand ? $t('REISEN.COUNTRIES.' + kunde.aktuellsteLand) : '-' }}</span
                  >
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.GEBURTSDATUM') }}</span>
                  <span class="text-muted">{{
                    kunde.geburtsdatum ? $options.filters.date(kunde.geburtsdatum) : '-'
                  }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('AUTH.INPUT.EMAIL') }}</span>
                  <a :href="'mailto:' + kunde.email" class="d-flex text-nowrap text-primary">{{
                    kunde.email || '-'
                  }}</a>
                </div>
              </div>

              <div class="col-md-4 d-flex flex-wrap flex-column" style="row-gap: 0.5rem">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('REISEN.COMMON.COUNTRY') }}</span>
                  <span class="text-muted">{{ kunde.land || '-' }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.ORT') }}</span>
                  <span class="text-muted">{{ kunde.ort || '-' }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.POSTLEITZAHL') }}</span>
                  <span class="text-muted">{{ kunde.postleitzahl || '-' }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.STRASSE') }}</span>
                  <span class="text-muted">{{ kunde.strasse || '-' }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.TELEFONPRIVAT') }}</span>
                  <span class="text-muted">{{ kunde.telefonPrivat || '-' }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.TELEFONMOBIL') }}</span>
                  <span class="text-muted">{{ kunde.telefonMobil || '-' }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{
                    $t('COMMON.TELEFONGESCHAEFTLICH')
                  }}</span>
                  <span class="text-muted">{{ kunde.telefonGeschaeftlich || '-' }}</span>
                </div>
              </div>

              <div class="col-md-4 d-flex flex-wrap flex-column" style="row-gap: 0.5rem">
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.CREATEDAT') }}</span>
                  <span class="text-muted">{{ kunde.createdDateUtc | date }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.UPDATEDAT') }}</span>
                  <span class="text-muted">{{ kunde.updatedDateUtc | date }}</span>
                </div>

                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('COMMON.KATALOGGEWUENSCHT') }}</span>
                  <span class="rounded d-flex text-nowrap p-2 bg-light-primary text-primary">
                    <i
                      :class="
                        !kunde.keinKatalogGewuenscht
                          ? 'fas fa-check text-success'
                          : 'fas fa-times text-danger'
                      "
                  /></span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap text-nowrap font-weight-bold mr-2">{{
                    $t('COMMON.AKTUELLSTERABREISETAG')
                  }}</span>
                  <span class="text-muted">{{
                    kunde.aktuellsterAbreisetag ? $options.filters.date(kunde.aktuellsterAbreisetag) : '-'
                  }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{ $t('KUNDEN.KUNDESEIT') }}</span>
                  <span class="text-muted">{{
                    kunde.kundeSeit ? $options.filters.date(kunde.kundeSeit) : '-'
                  }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{
                    $t('COMMON.LETZTESBUCHUNGSDATUM')
                  }}</span>
                  <span class="text-muted">{{
                    kunde.letzteBuchungsdatum ? $options.filters.date(kunde.letzteBuchungsdatum) : '-'
                  }}</span>
                </div>
                <div class="d-flex align-items-center justify-content-between">
                  <span class="text-nowrap font-weight-bold mr-2">{{
                    $t('KUNDEN.ZULETZTANGELEGTENICHTBUCHUNG')
                  }}</span>
                  <span class="text-muted">{{
                    kunde.zuletztAngelegteNichtBuchung
                      ? $options.filters.date(kunde.zuletztAngelegteNichtBuchung)
                      : '-'
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-skeleton v-else height="55px" width="500px"></b-skeleton>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    kunde: Object,
    isInitialLoading: Boolean,
  },
  data() {
    return {};
  },
  computed: {},
};
</script>
